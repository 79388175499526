import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import { InputLabel } from "./InputLabel";
import swal from "sweetalert";
import { useContext, useEffect, useState } from "react";
import { API, UserContext } from "../../App";

const openAiVoices = ["alloy", "echo", "fable", "onyx", "nova", "shimmer"];
const studioVoices = ["en-US-Studio-O", "en-US-Studio-Q"];

// https://cloud.google.com/text-to-speech/docs/voices
export const voices = [
    {
        lang: "en-US",
        name_lang: "English",
        voices: [
            "en-US-Standard-B",
            "en-US-Standard-C",
            "en-US-Standard-D",
            "en-US-Standard-E",
            "en-US-Wavenet-B",
            "en-US-Wavenet-C",
            "en-US-Wavenet-D",
            "en-US-Wavenet-E",
            "en-US-Wavenet-F",
            "en-US-Neural2-C",
            "en-US-Neural2-D",
            "en-US-Neural2-E",
            "en-US-Neural2-F",
        ],
    },
    {
        lang: "cs-CZ",
        name_lang: "Čeština",
        voices: ["cs-CZ-Standard-A", "cs-CZ-Wavenet-A"],
    },
    {
        lang: "ru-RU",
        name_lang: "Русский",
        voices: [
            "ru-RU-Standard-B",
            "ru-RU-Standard-C",
            "ru-RU-Standard-D",
            "ru-RU-Standard-E",
            "ru-RU-Wavenet-B",
            "ru-RU-Wavenet-C",
            "ru-RU-Wavenet-D",
            "ru-RU-Wavenet-E",
        ],
    },
    {
        lang: "uk-UA",
        name_lang: "Українська",
        voices: ["uk-UA-Standard-A", "uk-UA-Wavenet-A"],
    },
    {
        lang: "de-DE",
        name_lang: "Deutsch",
        voices: [
            "de-DE-Standard-A",
            "de-DE-Standard-B",
            "de-DE-Standard-C",
            "de-DE-Standard-D",
            "de-DE-Wavenet-A",
            "de-DE-Wavenet-B",
            "de-DE-Wavenet-C",
            "de-DE-Wavenet-D",
            "de-DE-Wavenet-E",
            "de-DE-Neural2-B",
            "de-DE-Neural2-C",
            "de-DE-Neural2-D",
            "de-DE-Neural2-F",
        ],
    },
    {
        lang: "nl-NL",
        name_lang: "Nederlands",
        voices: [
            "nl-NL-Standard-A",
            "nl-NL-Standard-B",
            "nl-NL-Standard-C",
            "nl-NL-Standard-D",
            "nl-NL-Standard-E",
            "nl-NL-Wavenet-B",
            "nl-NL-Wavenet-C",
            "nl-NL-Wavenet-D",
            "nl-NL-Wavenet-E",
        ],
    },
    {
        lang: "pl-PL",
        name_lang: "Polski",
        voices: [
            "pl-PL-Standard-A",
            "pl-PL-Standard-B",
            "pl-PL-Standard-C",
            "pl-PL-Standard-D",
            "pl-PL-Standard-E",
            "pl-PL-Wavenet-A",
            "pl-PL-Wavenet-B",
            "pl-PL-Wavenet-C",
            "pl-PL-Wavenet-D",
        ],
    },
    {
        lang: "sv-SE",
        name_lang: "Svenska",
        voices: [
            "sv-SE-Standard-A",
            "sv-SE-Standard-B",
            "sv-SE-Standard-C",
            "sv-SE-Standard-D",
            "sv-SE-Standard-E",
            "sv-SE-Wavenet-A",
            "sv-SE-Wavenet-B",
            "sv-SE-Wavenet-C",
            "sv-SE-Wavenet-D",
            "sv-SE-Wavenet-E",
        ],
    },
    {
        lang: "da-DK",
        name_lang: "Dansk",
        voices: [
            "da-DK-Standard-A",
            "da-DK-Standard-C",
            "da-DK-Standard-D",
            "da-DK-Standard-E",
            "da-DK-Wavenet-A",
            "da-DK-Wavenet-C",
            "da-DK-Wavenet-D",
            "da-DK-Wavenet-E",
            "da-DK-Neural2-D",
            "da-DK-Neural2-E",
        ],
    },
    {
        lang: "nb-NO",
        name_lang: "Norsk bokmål",
        voices: [
            "nb-NO-Standard-A",
            "nb-NO-Standard-B",
            "nb-NO-Standard-C",
            "nb-NO-Standard-D",
            "nb-NO-Standard-E",
            "nb-NO-Wavenet-A",
            "nb-NO-Wavenet-B",
            "nb-NO-Wavenet-C",
            "nb-NO-Wavenet-D",
            "nb-NO-Wavenet-E",
        ],
    },
    {
        lang: "fi-FI",
        name_lang: "Suomi",
        voices: ["fi-FI-Standard-A", "fi-FI-Wavenet-A"],
    },
    {
        lang: "es-ES",
        name_lang: "Español",
        voices: [
            "es-ES-Standard-A",
            "es-ES-Standard-B",
            "es-ES-Standard-C",
            "es-ES-Standard-D",
            "es-ES-Wavenet-B",
            "es-ES-Wavenet-C",
            "es-ES-Wavenet-D",
            "es-ES-Neural2-A",
            "es-ES-Neural2-B",
            "es-ES-Neural2-C",
            "es-ES-Neural2-D",
            "es-ES-Neural2-E",
        ],
    },
    {
        lang: "pt-BR",
        name_lang: "Português",
        voices: [
            "pt-BR-Standard-A",
            "pt-BR-Standard-B",
            "pt-BR-Standard-C",
            "pt-BR-Neural2-A",
            "pt-BR-Neural2-B",
            "pt-BR-Neural2-C",
        ],
    },
    {
        lang: "fr-FR",
        name_lang: "Français",
        voices: [
            "fr-FR-Standard-A",
            "fr-FR-Standard-B",
            "fr-FR-Standard-C",
            "fr-FR-Standard-D",
            "fr-FR-Neural2-A",
            "fr-FR-Neural2-B",
            "fr-FR-Neural2-C",
            "fr-FR-Neural2-D",
            "fr-FR-Neural2-E",
        ],
    },
    {
        lang: "it-IT",
        name_lang: "Italiano",
        voices: ["it-IT-Neural2-A", "it-IT-Neural2-C"],
    },
    {
        lang: "el-GR",
        name_lang: "Ελληνικά",
        voices: ["el-GR-Standard-A", "el-GR-Wavenet-A"],
    },
    {
        lang: "hu-HU",
        name_lang: "Magyar",
        voices: ["hu-HU-Standard-A", "hu-HU-Wavenet-A"],
    },
    {
        lang: "ja-JP",
        name_lang: "日本語",
        voices: ["ja-JP-Neural2-B", "ja-JP-Neural2-C", "ja-JP-Neural2-D"],
    },
    {
        lang: "tr-TR",
        name_lang: "Türkçe",
        voices: ["tr-TR-Wavenet-A", "tr-TR-Wavenet-B", "tr-TR-Wavenet-C", "tr-TR-Wavenet-D", "tr-TR-Wavenet-E"],
    },
    {
        lang: "he-IL",
        name_lang: "עברית",
        voices: [
            "he-IL-Standard-A",
            "he-IL-Standard-B",
            "he-IL-Standard-C",
            "he-IL-Standard-D",
            "he-IL-Wavenet-A",
            "he-IL-Wavenet-B",
            "he-IL-Wavenet-C",
            "he-IL-Wavenet-D",
        ],
    },
    {
        lang: "ko-KR",
        name_lang: "한국어",
        voices: ["ko-KR-Neural2-A", "ko-KR-Neural2-B", "ko-KR-Neural2-C"],
    },
    {
        lang: "vi-VN",
        name_lang: "Tiếng Việt",
        voices: ["vi-VN-Standard-A", "vi-VN-Standard-B", "vi-VN-Standard-C", "vi-VN-Standard-D"],
    },
    {
        lang: "id-ID",
        name_lang: "Bahasa Indonesia",
        voices: [
            "id-ID-Standard-A",
            "id-ID-Standard-B",
            "id-ID-Standard-C",
            "id-ID-Standard-D",
            "id-ID-Wavenet-A",
            "id-ID-Wavenet-B",
            "id-ID-Wavenet-C",
            "id-ID-Wavenet-D",
        ],
    },
    {
        lang: "zh-CN",
        name_lang: "中文",
        voices: ["cmn-CN-Wavenet-A", "cmn-CN-Wavenet-B", "cmn-CN-Wavenet-C", "cmn-CN-Wavenet-D"],
    },
    {
        lang: "ar-XA",
        name_lang: "العربية",
        voices: ["ar-XA-Wavenet-A", "ar-XA-Wavenet-B", "ar-XA-Wavenet-C", "ar-XA-Wavenet-D"],
    },
];

export function VoiceSelector({ voice, onChange, silver, lang }) {
    const [elevenVoices, setElevenVoices] = useState([]);
    const user = useContext(UserContext);

    useEffect(() => {
        const fetchVoices = async () => {
            const response = await fetch(API + `/voices`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
            });
            const data = await response.json();
            setElevenVoices(data?.map(v => v.name));
        };
        if (user?.token && silver) {
            fetchVoices();
        }
    }, [silver, user?.token]);

    let v = voices.find((v) => v.lang === lang)?.voices;
    if (silver) {
        v = v.concat(openAiVoices);
        v = v.concat(elevenVoices);
    }
    if (silver && lang === "en-US") {
        v = v.concat(studioVoices);
    }
    return (
        <Grid item xs={6} md={3}>
            <FormControl fullWidth>
                <InputLabel caption="voice" />
                <Select
                    variant="outlined"
                    value={voice}
                    onChange={(e) => {
                        if (e.target.value.includes("Studio") || elevenVoices.includes(e.target.value)) {
                            swal("Are you sure?", "This voice is 3x more expensive", "warning");
                        }
                        const audio = new Audio(
                            openAiVoices.includes(e.target.value)
                                ? `https://cdn.openai.com/API/docs/audio/${e.target.value}.wav`
                                : `https://cloud.google.com/static/text-to-speech/docs/audio/${e.target.value}.wav`
                        );
                        audio.play();
                        onChange(e.target.value);
                    }}
                >
                    {v?.map((voice) => (
                        <MenuItem key={voice} value={voice}>
                            {voice}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}
